import React from "react";
import ConstructionScreen from "./Construction Screen/ConstructionScreen";

function App() {
  return (
    <div className="App">
      <ConstructionScreen />
    </div>
  );
}

export default App;
